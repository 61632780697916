import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "@fortawesome/fontawesome-free/css/all.min.css"

import configs from "../../site-config"

const PrivacyPage = ({ data }) => (
  <Layout>
    <SEO title="Home" keywords={configs.app_keywords} />
    <section class="legalPage">
      <h1 class="legal-title">Política de Privacidad</h1>
      <p class="legal-subtitle">Última actualización: Diciembre 2019</p>
      <ol>
        <h3>
          <li>INTRODUCCIÓN</li>
        </h3>
        <p>
          Mediante la presente Política de Privacidad se explica cómo Vigilante
          Renfe recopila, utiliza, almacena, comparte y, en general, trata su
          Información Personal y/u otro tipo de información de o acerca de los
          usuarios (denominada conjuntamente "Información"). Por Información
          Personal se entiende aquella información que puede ser utilizada para
          identificar al usuario.
        </p>
        <p>
          Al acceder y/o utilizar las aplicaciones o sitio web de Vigilante
          Renfe (los "Servicios"), el usuario autoriza la recopilación,
          transferencia, manipulación, almacenamiento y divulgación de su
          información y otros usos, conforme se describe en la presente Política
          de Privacidad.
        </p>
        <p>
          Si el usuario tiene alguna duda acerca de esta Política de Privacidad
          o sobre cómo Vigilante Renfe usa su Información Personal, cookies o
          tecnologías similares, puede contactar con Vigilante Renfe en
          hola@vigilanterenfe.es[a][b][c], o en su defecto, dejar de utilizar
          los Servicios.
        </p>
        <p>
          Tal como se establece en los Términos y Condiciones con respecto a los
          Servicios ("Condiciones de Servicio"), el usuario debe ser mayor de
          edad, de acuerdo con las leyes de su estado o país de residencia, o
          bien tener el consentimiento de sus padres o tutor legal, y que sus
          padres o tutor legal estén de acuerdo con estas Políticas de
          Privacidad. Para los usuarios que se encuentren en la Unión Europea,
          el usuario debe tener al menos 16 años para acceder y/o utilizar los
          Servicios. Si el usuario es menor de 16 años debe contar con el
          consentimiento de su tutor legal y solo en la medida en que el tutor
          legal está de acuerdo con esta Política de Privacidad para acceder y/o
          utilizar los Servicios. En caso de que la legislación nacional
          aplicable del usuario lo permita, el usuario menor de 16 años puede
          acceder al servicio, siempre que esta no sea inferior a 13 años.
        </p>
        <p>
          El responsable del tratamiento de su Información Personal por ser
          quien presta los Servicios es:
        </p>
        <ul>
          <li>
            <p>Vigilante Renfe</p>
          </li>
        </ul>
        <h3>
          <li>INFORMACIÓN QUE RECOPILAMOS</li>
        </h3>
        <p>
          Vigilante Renfe, a fin de poder proporcionar sus Servicios, recibe y
          trata Información del usuario, tal como:
        </p>
        <ol>
          <li>
            <p>
              Información que nos facilita el usuario: cuando el usuario
              complete los formularios en el momento de acceder o utilizar los
              Servicios, o cree una cuenta con nosotros, recopilamos la
              Información Personal que nos facilita, que puede incluir su correo
              electrónico, nombre, nombre de usuario, domicilio y número de
              teléfono, una biografía corta, su página web personal, fotografía,
              ubicación, y otra información personal que el usuario decida
              proporcionarnos o incluir en su cuenta.
            </p>
            <p>
              Asimismo, podremos recopilar Información Personal que el usuario
              proporcione al comunicarse con Vigilante Renfe, o al comunicarse
              con otros usuarios de los Servicios.
            </p>
          </li>
          <li>
            <p>
              Información que obtenemos automáticamente: asimismo, recopilamos
              información sobre el usuario y sobre el uso de los Servicios, de
              las interacciones con nosotros y nuestra publicidad, así como
              información relacionada con el dispositivo electrónico utilizado
              para acceder o utilizar los Servicios (por ejemplo, teléfono móvil
              o tablets). Esta información incluye:
            </p>
            <ul>
              <li>
                <p>
                  Algunos de nuestros Servicios permiten que los usuarios creen
                  alertas relacionadas con los servicios de transporte de Renfe.
                  Cuando el usuario envía estas alertas, recopilamos esa
                  información.
                </p>
              </li>
              <li>
                <p>
                  Cuando el usuario solicita asistencia al equipo de soporte al
                  cliente de Vigilante Renfe, la información de contacto que
                  proporciona será recopilada, así como también lo será la
                  información acerca de su uso de las aplicaciones o su
                  actividad en el Servicio, su número de identificación de
                  usuario y la correspondencia y cualquier tipo de información
                  allí contenida.
                </p>
              </li>
              <li>
                <p>
                  Datos de registro e información de uso de las aplicacione: los
                  Servicios registran información creada mediante el uso de los
                  Servicios por parte del usuario de manera automática. Estos
                  datos pueden incluir información como su dirección IP, tipo de
                  navegador, sistema operativo, sitio web que lo deriva, páginas
                  visitadas, ubicación, compañía de servicios móviles, ID del
                  dispositivo y de la aplicación, términos de búsqueda, e
                  información de cookies. Los datos de registro se reciben
                  cuando el usuario interactúa con los Servicios. Estos datos se
                  utilizan para brindar los Servicios y para evaluarlos,
                  personalizarlos y mejorarlos. Vigilante Renfe también recopila
                  y almacena el historial de uso de las aplicaciones del
                  usuario, lo que incluye, para algunas de ellas, su historial
                  de alertas relacionadas con los servicios de transporte de
                  Renfe.
                </p>
              </li>
              <li>
                <p>
                  Si el usuario publica su ubicación en su perfil de Vigilante
                  Renfe, recopilamos información acerca de la ubicación del
                  usuario. Asimismo, podemos utilizar y almacenar información
                  acerca de su ubicación a través del GPS, información acerca de
                  las redes inalámbricas o torres de comunicaciones cerca de su
                  dispositivo móvil, o su dirección IP, y ello para brindar
                  funcionalidades especiales de nuestros Servicios, para
                  proporcionar anuncios que se relacionen con sus intereses, y/o
                  para mejorar y personalizar los Servicios, por ejemplo, con
                  contenido más relevante y sugerencias para los usuarios.
                  Nuestros socios publicitarios podrán obtener información de
                  ubicación tal como se describe en la cláusula "Publicidad y
                  Analíticas de Terceros" más adelante en el documento. Si el
                  usuario no desea que nosotros o nuestros socios publicitarios
                  recopilen información de ubicación de su dispositivo móvil,
                  siga las instrucciones de la Cláusula "12.3" a continuación.
                </p>
              </li>
              <li>
                <p>
                  Vigilante Renfe podrá llevar un registro de cómo el usuario
                  interactúa con enlaces que se encuentran en todos los
                  Servicios, lo que incluye las preferencias del usuario
                  respecto de notificaciones por correo electrónico, y la
                  interacción con los servicios de terceros y con las
                  aplicaciones de clientes, a través del re-direccionamiento de
                  clics u otros medios.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              Información proveniente de otras fuentes: También recopilamos
              Información sobre el usuario y sobre el uso de los Servicios de
              otras fuentes como:
            </p>
            <ul>
              <li>
                <p>
                  Proveedores de servicios de pago: cuando el usuario realiza
                  una compra en los Servicios, Vigilante Renfe puede recopilar
                  la información financiera y de facturación necesaria para
                  procesar los cargos; y/o hacerlo en nombre de los proveedores
                  de servicios de pagos pertinentes. Las Compras de servicios
                  pertenecientes a terceros están sujetas a las políticas
                  aplicables a los terceros.
                </p>
              </li>
              <li>
                <p>
                  Proveedores de servicios de terceros: Vigilante Renfe utiliza
                  una variedad de servicios de terceros para asistir a la
                  provisión de los Servicios, tales como servicios de hosting,
                  entre otros. Estos terceros proveedores de servicios podrán
                  recopilar información enviada desde el navegador del usuario
                  como parte de una solicitud de una página web, tal como
                  cookies o su dirección IP, ubicación, e identificadores únicos
                  de dispositivos.
                </p>
              </li>
            </ul>
          </li>
        </ol>
        <h3>
          <li>USO DE INFORMACIÓN POR PARTE DE Vigilante Renfe</li>
        </h3>
        <p>
          El usuario acepta que Vigilante Renfe, sus Proveedores de Servicios
          y/o sus Socios Publicitarios, en nombre de Vigilante Renfe, puedan
          utilizar la información que recopilan para los siguientes fines:
        </p>
        <ul>
          <li>
            <p>
              proporcionar, personalizar y mejorar los Servicios y publicidad
              (incluidos productos y servicios de terceros) disponibles a través
              o fuera del Servicio;
            </p>
          </li>
          <li>
            <p>
              mostrarle publicidad de nuestros socios y otros que puedan ser de
              su interés;
            </p>
          </li>
          <li>
            <p>
              contactarlo como parte del soporte a usuarios; para enviarle
              actualizaciones o información acerca de los Servicios;
            </p>
          </li>
          <li>
            <p>
              gestionar su cuenta y su relación con los Servicios y mejorar su
              experiencia al utilizarlos;
            </p>
          </li>
          <li>
            <p>
              mejorar los Servicios, investigar, realizar encuestas y vincularse
              con el usuario, por ejemplo, enviándole comunicaciones con estos
              fines;
            </p>
          </li>
          <li>
            <p>
              marketing y promoción de los Servicios y productos, fines
              comerciales directos y de terceros;
            </p>
          </li>
          <li>
            <p>
              personalizar y optimizar los Servicios, el contenido promocional
              y/o la publicidad;
            </p>
          </li>
          <li>
            <p>procesar pagos o prevenir y detectar fraudes;</p>
          </li>
          <li>
            <p>
              crear informes, análisis o servicios similares con fines de
              investigación o inteligencia empresarial;
            </p>
          </li>
          <li>
            <p>
              compartir con otros usuarios para poder brindar los Servicios y
              favorecer la interacción entre los usuarios dentro de los
              Servicios.
            </p>
          </li>
        </ul>
        <p>
          El usuario podrá utilizar la configuración de su cuenta para darse de
          baja de algunas notificaciones de Vigilante Renfe y/o de otros
          usuarios. También podrá darse de baja de algunos mensajes a través de
          las instrucciones contenidas en la notificación.
        </p>
        <h3>
          <li>COMUNICACIÓN DE LA INFORMACIÓN CON TERCEROS</li>
        </h3>
        <p>
          Vigilante Renfe podrá compartir la Información Personal del usuario
          con:
        </p>
        <ul>
          <li>
            <p>
              Proveedores de Servicios. Es posible que establezcamos relaciones
              comerciales con otras compañías que estimemos confiables luego de
              haber confirmado que sus prácticas de privacidad son coherentes
              con las nuestras. Por ejemplo, podremos celebrar contratos con
              Proveedores de Servicios para que nos brinden ciertos servicios
              como hosting y mantenimiento, almacenamiento y gestión de datos,
              marketing y promociones, y para procesar pagos y compras que el
              usuario haya autorizado. Solo le proporcionamos a nuestros
              Proveedores de Servicios la información necesaria para que lleven
              a cabo estos servicios en nuestro nombre. Cada Proveedor de
              Servicios debe acordar usar prácticas y procedimientos de
              seguridad razonables, adecuados a la naturaleza de la información
              involucrada, con el fin de proteger la Información Personal del
              usuario de acceso, uso o divulgación no autorizados. Los
              Proveedores de Servicios no tienen permitido utilizar su
              Información Personal de formas diferentes a las indicadas por
              nosotros.
            </p>
          </li>
          <li>
            <p>
              Terceros y Público en general. Al utilizar el Sitio web o los
              Servicios, es posible que el usuario facilite cierta Información
              Personal a otros. Por ejemplo, al crear un perfil, cualquier otro
              usuario podrá ver su nombre de usuario o seudónimo, así como otra
              información de su perfil que el usuario haya elegido hacer pública
              (como su nombre o nombre de usuario, una breve biografía, su
              ubicación, página web personal, número de teléfono móvil,
              fotografía y otra información la ciudad en la que reside, país, y
              otra información que elija facilitar a otros). A su vez, cuando el
              usuario nos envía contenido (lo cual incluye alertas relacionadas
              con los servicios de transporte de Renfe), nos da su autorización
              para utilizar ese contenido para cualquier fin, lo que incluye la
              publicación y distribución de dicho contenido a través de nuestros
              Servicios o fuera de estos mediante terceros. Cuando el usuario
              participa en competiciones, es posible que publiquemos,
              compartamos o divulguemos su Información Personal y otra
              información acerca del usuario relacionada con la competición.
            </p>
          </li>
          <li>
            <p>
              Socios de marketing que nos ayudan con esfuerzos promocionales y
              publicistas que hacen posible que ofrezcamos los Servicios de
              forma gratuita. Tal como se describe en la Cláusula 12 relativa a
              "Publicidad y Analíticas de Terceros – Divulgación Publicitaria
              basada en Intereses", nuestros socios publicitarios podrán
              recopilar identificadores publicitarios y otra Información
              Personal con el fin de brindarle publicidad basada en los
              Intereses del usuario.
            </p>
          </li>
          <li>
            <p>
              Otros usuarios de los Servicios. Cuando el usuario chatea con
              otros usuarios, envía comunicaciones o publica mensajes de manera
              pública o privada, esas comunicaciones, comentarios y chats son
              compartidas con los destinatarios a quienes van dirigidos, y estos
              podrán compartir las comunicaciones con otros a través de los
              Servicios o fuera de estos. El usuario comprende que, una vez que
              comparte comunicaciones o su foto de perfil con otros, incluso
              cuando únicamente las comparte con uno o dos usuarios, esa
              información deja de ser privada y no podrá volver a serlo.
              Vigilante Renfe no puede controlar las acciones de otros usuarios
              con los que el usuario se comunica o comparte información.
            </p>
          </li>
          <li>
            <p>
              En cumplimiento de la legislación vigente y con las organizaciones
              públicas, y protección de nuestro Sitio, Servicios y otros.
              Vigilante Renfe podrá divulgar la Información Personal del usuario
              en la medida en que razonablemente sea necesario para cumplir con
              una ley, regulación o solicitud legal por parte de los organismos
              competentes. Podremos acceder, leer, conservar y divulgar la
              Información Personal del usuario, su información no personal, su
              contenido de usuario, registros de chats u otras comunicaciones,
              cualquier contenido que publique o suba, y cualquier otra
              información acerca del usuario, a funcionarios del gobierno y del
              orden público u organismos privados, si a nuestra entera
              discreción, lo estimamos necesario o apropiado con el fin de (i)
              cumplir con cualquier ley aplicable (lo cual incluye órdenes
              judiciales y citaciones), (ii) investigar potenciales violaciones
              de, o hacer cumplir nuestros "Condiciones de Servicio", (iii)
              detectar, prevenir, o abordar de otra forma problemas de fraude,
              seguridad, o técnicos, (iv) responder a solicitudes de soporte al
              usuario, (v) proteger la seguridad, propiedad, o derechos de
              nuestra compañía, nuestros usuarios, o de cualquier tercero, o
              (vi) prevenir o detener cualquier actividad ilegal, no ética o
              punible jurídicamente.
            </p>
          </li>
          <li>
            <p>
              Información anónima o agregada. Vigilante Renfe podrá utilizar y
              compartir información agregada y/o anónima acerca de los usuarios
              con terceros. El usuario no podrá ser personalmente identificable
              a través de esa información.
            </p>
          </li>
          <li>
            <p>
              Otras comunicaciones. Podremos compartir Información (lo que
              incluye la Información Personal y otra información y datos acerca
              de los usuarios) con compañías que controlen, sean controladas
              por, o estén bajo el mismo control que nuestra Compañía. Si
              nuestra Compañía se fusiona, es adquirida, vendida, o en caso de
              que se transfiera una parte o la totalidad de nuestros bienes,
              podremos divulgar o transferir Información Personal y Datos de Uso
              en relación con dicha transacción. El usuario tendrá la
              oportunidad de optar por excluir cualquier tipo de transferencia
              en caso de que, a nuestra discreción, dé lugar al manejo de su
              Información Personal de una manera que difiera materialmente de
              estas Políticas de Privacidad.
            </p>
          </li>
        </ul>
        <p>
          El usuario reconoce que no tiene ninguna expectativa de privacidad en
          lo que respecta a la información mencionada anteriormente, lo que
          incluye sus chats y comunicaciones con otros usuarios o con nosotros,
          el contenido que nos envía (que incluye alertas relacionadas con los
          servicios de transporte de Renfe), y el contenido que proporciona en
          relación con competiciones, información de su perfil que elige hacer
          pública, o su utilización de estas funciones del Sitio o de los
          Servicios.
        </p>
        <p>
          Si el usuario es residente de California, debe dirigirse a la cláusula
          8.3 "Derechos de privacidad de los usuarios de California" para
          obtener más información.
        </p>
        <h3>
          <li>CONSENTIMIENTO</li>
        </h3>
        <p>
          La base jurídica para el tratamiento de los datos personales descritos
          en esta Política de Privacidad dependerá de los datos personales
          implicados y del contexto específico en el cual los recojamos y
          utilicemos. Podemos tratar la Información sobre la base de:
        </p>
        <ul>
          <li>
            <p>
              La necesidad del tratamiento para la ejecución del contrato
              suscrito con el usuario (para prestarle nuestros Servicios).
            </p>
          </li>
          <li>
            <p>
              Nuestro interés legítimo, sin que entre en conflicto con los
              propios intereses de protección de los datos, los derechos o
              libertades fundamentales del usuario (realización de estadísticas
              y ayudarnos a comprender mejor nuestras necesidades y mejorar los
              Servicios).
            </p>
          </li>
          <li>
            <p>
              En algunos casos, obligación legal de recopilar tus datos
              personales.
            </p>
            <p>
              En todo caso, al acceder y/o utilizar los Servicios de Vigilante
              Renfe, el usuario presta su consentimiento para:
            </p>
          </li>
          <li>
            <p>
              Recopilar, almacenar, usar, compartir y realizar otro tratamiento
              de su Información Personal, incluso para fines publicitarios,
              conforme a la presente Política de Privacidad.
            </p>
          </li>
          <li>
            <p>
              Transmitir su Información Personal fuera de su país de residencia.
            </p>
          </li>
          <li>
            <p>Usar cookies y otras tecnologías similares.</p>
          </li>
        </ul>
        <h3>
          <li>CUÁNTO TIEMPO CONSERVAMOS LA INFORMACIÓN </li>
        </h3>
        <p>
          Vigilante Renfe puede conservar la Información (lo que incluye la
          Información Personal) durante el tiempo que sea necesario para cumplir
          con los propósitos para los que fue recopilada, o , incluso después de
          que el usuario haya discontinuado o eliminado cualquier cuenta o una
          vez finalice la provisión de los Servicios, si la conservación de
          dicha Información es necesaria para cumplir con obligaciones legales,
          cumplir con requisitos reglamentarios, resolver disputas entre
          usuarios, prevenir fraudes, respaldar, archivar, y/o auditar, proteger
          los derechos o la propiedad de Vigilante Renfe, o cualquier otro uso.
        </p>
        <p>
          Tiempo que conservamos la información para usuarios que se encuentren
          en Europa
        </p>
        <p>
          Para determinar el período de conservación de la Información, seguimos
          los siguientes criterios:
        </p>
        <ul>
          <li>
            <p>
              Información obtenida para la prestación de los Servicios, enviarle
              actualizaciones o información acerca de los Servicios y gestionar
              su cuenta: mientras no nos pida que cerremos su cuenta;
            </p>
          </li>
          <li>
            <p>
              Información obtenida al prestar su consentimiento para el envío de
              comunicaciones comerciales y publicidad: hasta que cancele la
              suscripción o exija que lo eliminemos.
            </p>
          </li>
          <li>
            <p>
              Es posible que conservemos alguna Información para cumplir con
              nuestras obligaciones legales, así como para administrar nuestros
              derechos (por ejemplo, para hacer valer nuestras reclamaciones
              ante los tribunales) o con fines estadísticos o históricos.
            </p>
          </li>
          <li>
            <p>
              Cuando ya no necesitemos usar sus datos personales, se eliminarán
              de nuestros sistemas y registros o se anonimizarán para que ya no
              podamos identificarlos.
            </p>
          </li>
        </ul>
        <h3>
          <li>TRANSFERENCIAS INTERNACIONALES DE DATOS</li>
        </h3>
        <p>
          La Información Personal del usuario puede ser transferida, y
          almacenada en servidores ubicados fuera de su país de residencia, a
          terceros países fuera del Espacio Económico Europeo (o EEA), incluidos
          países en los que las leyes en materia de privacidad no sean tan
          protectoras como las de su jurisdicción.
        </p>
        <p>
          Entre estos terceros países pueden estar Argentina y Uruguay, respecto
          a los cuales la Comisión Europea los ha declarados como países con
          nivel adecuado de protección mediante la Decisión 2003/490/CE de la
          Comisión, de 30 de junio de 2003 (Argentina) y Decisión 2012/484/UE de
          la Comisión, de 21 de agosto de 2012 (Uruguay).
        </p>
        <p>
          También se pueden transferir datos personales a Estados Unidos o a
          otro país y procesarla allí. Siempre que transferimos la Información
          del usuario fuera de la jurisdicción en la cual se encuentra, nos
          aseguramos de garantizar que la transferencia internacional de datos
          se realiza conforme establece la normativa aplicable.
        </p>
        <p>
          Independientemente del país en el que resida o suministre información,
          el usuario autoriza a Vigilante Renfe a almacenar y/o utilizar la
          Información conforme a esta Política de Privacidad en cualquier país
          donde Vigilante Renfe pueda operar.
        </p>
        <h3>
          <li>DERECHOS DEL USUARIO</li>
        </h3>
        <ol>
          <li>
            <p>Derechos de los usuarios</p>
            <p>
              El usuario puede revisar, corregir, actualizar o cambiar su
              Información Personal cambiando la configuración pertinente de su
              cuenta.
            </p>
            <p>
              El usuario puede optar por no recibir determinadas comunicaciones
              comerciales por vía electrónica a través de la opción "darse de
              baja" contenida dentro de dichos correos electrónicos. No
              obstante, no puede optar por no recibir todas las comunicaciones
              de Vigilante Renfe, tales como correos electrónicos sobre el
              estado de su cuenta.
            </p>
            <p>
              La presente Política de Privacidad no busca limitar las defensas u
              objeciones legales que el usuario pueda tener con respecto a un
              tercero, lo que incluye la solicitud de un gobierno de revelar su
              información.
            </p>
            <p>
              Por consultas o inquietudes con respecto a esta Política de
              Privacidad, envíenos un correo electrónico a
              hola@vigilanterenfe.es.
            </p>
          </li>
          <li>
            <p>
              Derechos de los usuarios que se encuentran en Europa Como titular
              de la Información Personal que recopilamos y tratamos, el usuario
              tiene los siguientes derechos:
            </p>
            <ul>
              <li>
                <p>
                  Derecho de Información: tiene derecho a obtener información
                  clara, transparente y fácil de entender sobre la forma en que
                  usamos sus datos personales y sobre sus derechos; información
                  que facilitamos al usuario a través de la presente Política de
                  Privacidad.
                </p>
              </li>
              <li>
                <p>
                  Derecho de acceso: tiene derecho a acceder y obtener copia de
                  la información que tratamos sobre su persona, con ciertos
                  límites legales.
                </p>
              </li>
              <li>
                <p>
                  Derecho de rectificación: derecho a que rectifiquemos sus
                  datos cuando resultaran inexactos o incompletos.
                </p>
              </li>
              <li>
                <p>
                  Derecho de supresión o derecho al olvido: derecho a que sus
                  datos personales sean borrados o eliminados cuando, entre
                  otros motivos, sus datos ya no sean necesarios para los fines
                  que fueron recogidos. No obstante, Vigilante Renfe podría
                  tener motivos legales o legítimos para conservarlos.
                </p>
              </li>
              <li>
                <p>
                  Derecho de oposición al tratamiento de sus datos en
                  determinadas circunstancias, por ejemplo, al marketing
                  directo, incluida la elaboración de perfiles.
                </p>
              </li>
              <li>
                <p>
                  Derecho a retirar el consentimiento prestado en cualquier
                  momento cuando el tratamiento esté basado en el
                  consentimiento.
                </p>
              </li>
              <li>
                <p>
                  Derecho de limitación del tratamiento: tiene derecho a
                  solicitar la limitación del tratamiento de sus datos en los
                  supuestos establecidos legalmente. Asimismo, el responsable
                  del tratamiento podrá limitar el tratamiento cuando el usuario
                  impugne la exactitud de los datos personales, durante el plazo
                  que permita al responsable verificar la exactitud de los
                  mismos; o cuando el usuario se oponga al tratamiento, mientras
                  se verifica si procede.
                </p>
              </li>
              <li>
                <p>
                  Derecho a la portabilidad de los datos: tiene derecho a
                  trasladar, copiar o transferir los datos que nos haya
                  facilitado en un formato estructurado, de uso común y lectura
                  mecánica, desde nuestra base de datos a otra distinta. Solo es
                  posible ejercer este derecho respecto a los datos que haya
                  facilitado, cuando el tratamiento esté basado en la ejecución
                  de un contrato o en su consentimiento y el tratamiento se
                  realice por medios automatizados.
                </p>
              </li>
              <li>
                <p>
                  Derecho a presentar una reclamación ante la Autoridad de
                  Control competente si considera que el tratamiento no se
                  ajusta a la normativa vigente.
                </p>
              </li>
            </ul>
            <p>
              Para el ejercicio de cualquiera de los derechos que le asisten al
              usuario residente europeo, puede remitir solicitud por escrito
              concretando los siguientes datos: nombre y apellidos, domicilio a
              efectos de notificaciones, copia del Documento Nacional de
              Identidad o Pasaporte, y petición en que se concreta la solicitud.
              En el caso de representación, deberá probarse la misma mediante
              documento fehaciente.
            </p>
            <p>
              Dicha solicitud deberá ser remitida a Vigilante Renfe por vía
              electrónica a hola@vigilanterenfe.es.
            </p>
          </li>
          <li>
            <p>Derechos de privacidad de los usuarios de California</p>
            <p>
              Los residentes de California tienen derecho a solicitar y obtener
              una vez al año cierta información acerca de nuestra divulgación,
              si la hubiera, de información personal a terceros para sus fines
              comerciales directos durante el año calendario inmediatamente
              anterior (por ejemplo, las solicitudes realizadas en 2018
              recibirán información acerca del intercambio de actividades
              realizado durante el 2017).
            </p>
            <p>
              Con el fin de cumplir con esta legislación, les ofrecemos a los
              usuarios de California la posibilidad de solicitarnos que no
              compartamos su información personal con terceros para fines de
              marketing directo. Para realizar dicha solicitud, envíe un correo
              electrónico a hola@vigilanterenfe.es. Deberá incluir "Derechos de
              privacidad de California" como asunto del correo, así como su
              nombre completo, correo electrónico y dirección postal en el
              cuerpo del mensaje.
            </p>
            <p>
              Los usuarios de California menores de 18 años de edad pueden
              solicitar que se elimine su contenido o información publicados en
              los Servicios mediante el envío de un correo electrónico a
              hola@vigilanterenfe.es. Tenga en cuenta que la mayor parte del
              contenido de los usuarios que aparece en nuestros Servicios es
              almacenada y controlada por proveedores externos o por otros
              usuarios, por lo tanto, es probable que no sea posible la
              eliminación integral y exhaustiva del contenido.
            </p>
          </li>
        </ol>
        <h3>
          <li>SEGURIDAD Y PROTECCIÓN DE LA INFORMACIÓN</li>
        </h3>
        <p>
          Vigilante Renfe mantiene medidas de índole técnica y organizativas
          apropiadas para proteger la Información contra toda alteración,
          pérdida, destrucción, tratamiento, o acceso no autorizado, así como
          cualquier otra forma ilegal de tratamiento de la Información Personal.
        </p>
        <p>
          Sin embargo, no se puede garantizar que el almacenamiento o
          transmisión de datos a través de Internet u otra red sea 100% seguro.
          En consecuencia, Vigilante Renfe no garantiza que la información no
          será accedida, divulgada, alterada o destruida por el incumplimiento
          de cualquiera de las medidas de seguridad antes mencionadas.
        </p>
        <h3>
          <li>ENLACES A SITIOS DE TERCEROS</li>
        </h3>
        <p>
          En los Servicios el usuario puede encontrar enlaces a sitios web de
          terceros. El usuario comprende que, al hacer clic en estos enlaces,
          cualquier dato que proporcione posteriormente se regirá conforme a la
          política de privacidad de dicho tercero y no a la de Vigilante Renfe.
          En consecuencia, Vigilante Renfe no asume ninguna responsabilidad por
          el contenido, protección o seguridad de cualquier sitio web de
          terceros y recomendamos que consulte estas políticas antes de enviar
          datos personales a sitios web de terceros.
        </p>
        <p>
          En particular, podemos ofrecer al usuario la oportunidad de
          registrarse en nuestros Servicios utilizando el inicio de sesión en
          redes sociales. Si lo hace, el usuario comprende que comparte su
          información de perfil social con Vigilante Renfe y que los datos
          personales compartidos dependen de la configuración de su plataforma
          de medios sociales. Consulte la política de privacidad de las redes
          sociales en cuestión para conocer cómo se trata su Información
          Personal.
        </p>
        <h3>
          <li>COOKIES</li>
        </h3>
        <p>
          Con la aceptación de la presente Política de Privacidad, al acceder
          y/o utilizar los Servicios, el usuario acepta la utilización de
          cookies y otras tecnologías similares por parte de Vigilante Renfe,
          así como de terceros como Facebook o alguno de nuestros socios
          publicitarios. Estos terceros pueden colocar y leer cookies y otras
          tecnologías similares, tales como identificadores de dispositivos,
          balizas web o "web beacons" y pixeles de rastreo o "tracking pixels",
          los cuales recopilan información anónima cuando el usuario utiliza los
          Servicios.
        </p>
        <p>
          Estas tecnologías se utilizan para poder prestar los Servicios al
          usuario, ofrecerle publicidad que resulte de su interés, así como
          mejorar los Servicios, facilitando también el uso de nuestras
          aplicaciones y plataforma web.
        </p>
        <p>¿Qué son las cookies?</p>
        <p>
          Las cookies y estas otras tecnologías son pequeños archivos o
          dispositivos que se descargan en el ordenador u otro equipo, como ser
          un dispositivo móvil, que utilice el usuario cuando accede y/o utiliza
          determinadas páginas web o determinados servicios online. Las cookies
          sirven para almacenar y recuperar datos sobre los hábitos de
          navegación del usuario, de su equipo, tiempo de conexión, analizar el
          funcionamiento de la página, etc. y, con ello, poder prestar
          determinados servicios, ofrecer publicidad o poder implementar mejoras
          o nuevos servicios.
        </p>
        <p>¿Qué cookies utilizamos?</p>
        <p>Vigilante Renfe y terceros utilizan las siguientes cookies:</p>
        <ul>
          <li>
            <p>
              Cookies técnicas: Son aquéllas que permiten al Usuario la
              navegación a través de una página web, plataforma o aplicación y
              la utilización de las diferentes opciones o servicios que en ella
              existan como, por ejemplo, controlar el tráfico y la comunicación
              de datos, identificar la sesión, acceder a partes de acceso
              restringido, utilizar elementos de seguridad durante la
              navegación, almacenar contenidos para la difusión de videos o
              sonido o compartir contenidos a través de redes sociales.
            </p>
          </li>
          <li>
            <p>
              Vigilante Renfe puede utilizar este tipo de cookies para reconocer
              el dispositivo del usuario, por ejemplo, a través de la
              identificación de su dirección IP, y para recopilar datos como el
              modelo de su dispositivo, sistema operativo y el tamaño de la
              pantalla, otras aplicaciones instaladas en su dispositivo, fecha y
              hora, la temática de las publicidades sobre las que ha cliqueado o
              por las que se ha desplazado, y otra información acerca de cómo
              utiliza los Servicios.
            </p>
          </li>
          <li>
            <p>
              Cookies de personalización: Son aquellas que permiten al usuario
              acceder al servicio con algunas características de carácter
              general predefinidas en función de una serie de criterios en el
              terminal del usuario como por ejemplo serian el idioma, el tipo de
              navegador a través del cual accede al servicio, la configuración
              regional desde donde accede al servicio, etc.
            </p>
            <p>
              Vigilante Renfe puede utilizar este tipo de cookies para almacenar
              información de acceso y así evitar que el usuario tenga que
              registrarse una y otra vez; para permitir que los Servicios
              interactúen con redes sociales o plataformas de terceros donde el
              usuario haya elegido permitir dicha interacción; para permitir que
              se procesen pagos cuando el usuario envíe instrucciones de pago;
              para permitir que Vigilante Renfe y terceros le proporcionen
              servicios más personalizados; y para recopilar otra información
              acerca de la utilización de nuestros Servicios.
            </p>
          </li>
          <li>
            <p>
              Cookies de análisis:Son aquéllas que permiten al responsable de
              las mismas, el seguimiento y análisis del comportamiento de los
              usuarios de los sitios web y/o servicios a los que están
              vinculadas. La información recogida mediante este tipo de cookies
              se utiliza en la medición de la actividad de los sitios web,
              aplicación o plataforma y para la elaboración de perfiles de
              navegación de los usuarios de dichos sitios, aplicaciones y
              plataformas, con el fin de introducir mejoras en función del
              análisis de los datos de uso que hacen los usuarios del servicio.
            </p>
          </li>
          <li>
            <p>
              Cookies publicitarias: Son aquéllas que permiten la gestión de los
              espacios publicitarios que terceros, tales como Facebook y
              nuestros socios publicitarios, hayan incluido en nuestra
              plataforma web y aplicaciones desde las que se presta el servicio
              solicitado, utilizándose para limitar la frecuencia y organizar la
              publicidad que se recibe.
            </p>
          </li>
          <li>
            <p>
              Cookies de publicidad comportamental:Son aquéllas que permiten la
              gestión de los espacios publicitarios que, en su caso, se hayan
              incluido en una página web, aplicación o plataforma desde la que
              presta el servicio solicitado. Estas cookies almacenan información
              del comportamiento de los usuarios obtenida a través de la
              observación continuada de sus hábitos de navegación, lo que
              permite desarrollar un perfil específico para mostrar publicidad
              que pueda resultarle importante o interesante.
            </p>
            <p>
              Terceros, tales como Facebook y nuestros socios publicitarios,
              podrán usar cookies dentro y fuera de los Servicios con los fines
              arriba mencionados, que incluye la recopilación de información
              acerca de la actividad en línea del usuario en el transcurso del
              tiempo y en los diferentes sitios web, y la utilización de esa
              información para facilitar anuncios en nuestros Servicios o en
              otras páginas web acerca de productos y servicios que
              probablemente sean de su interés.
            </p>
          </li>
          <p>
            En particular, Vigilante Renfe utiliza las siguientes cookies de
            terceros que recopilan información con fines estadísticos, de uso de
            la plataforma web por parte del Usuario y para incluir publicidad en
            nuestros Servicios:
          </p>
          <ul>
            <li>
              <p>
                Google Analytics de Google, Inc., una empresa cuya sede central
                se encuentra en 1600 Amphitheatre Parkway, Mountain View, CA
                94043, Estados Unidos, que se utiliza para la realización de
                nuestras estadísticas y análisis sobre el número de visitas,
                duración, datos socio demográficos del usuario (lenguaje,
                ubicación, proveedor de Internet…), etc.
              </p>
            </li>
            <li>
              <p>
                Facebook, Inc. una empresa cuya sede central se encuentra en
                1601 Willow Road, Menlo Park, California, Estados Unidos. Cuando
                nuestros usuarios utilizan nuestros Servicios a través de la
                plataforma web de Facebook, Facebook podrá utilizar cookies con
                diferentes objetivos. El usuario podrá encontrar la política de
                Facebook respecto a cookies en el siguiente link:{' '}
                <a
                  target="_blank"
                  href="https://www.facebook.com/policies/cookies/"
                  rel="noopener noreferrer"
                >
                  https://www.facebook.com/policies/cookies/
                </a>
              </p>
            </li>
          </ul>
        </ul>
        <p>
          El usuario podrá permitir, bloquear o desactivar las cookies
          instaladas en su equipo mediante la configuración del navegador web de
          su ordenador o su dispositivo móvil. La configuración de los
          navegadores de Internet suele estar programada por defecto para
          aceptar Cookies, pero pueden desactivarse fácilmente cambiando la
          configuración del navegador.
        </p>
        <p>
          Muchas cookies se utilizan para mejorar la usabilidad o funcionalidad
          de los sitios web/aplicaciones, por lo tanto, si el usuario decide
          desactivar las cookies, es posible que algunas funcionalidades de los
          Servicios no funcionen correctamente o su calidad puede verse
          afectada. Como alternativa, si el usuario no desea que se recopilen
          los datos conforme a lo descrito en la presente cláusula, deberá dejar
          de utilizar los Servicios.
        </p>
        <h3>
          <li>
            PUBLICIDAD Y ANALÍTICAS DE TERCEROS – DIVULGACIÓN PUBLICITARIA
            BASADA EN INTERESES
          </li>
        </h3>
        <ol>
          <li>
            Socios Publicitarios y Divulgación Publicitaria basada en Intereses:
            <ul>
              <li>
                <p>
                  permitimos la publicidad en nuestros Servicios gratuitos para
                  poder continuar ofreciendo dichos Servicios de manera
                  gratuita. Nuestros socios publicitarios podrán recopilar
                  identificadores publicitarios (tal como el Identificador para
                  Publicidad de Apple (IDFA, por sus siglas en inglés) o el ID
                  de Publicidad de Google (AAID, por sus siglas en inglés) para
                  habilitar y optimizar la publicidad que ofrecen, para brindar
                  anuncios personalizados relacionados con los intereses del
                  usuario ("Publicidad Basada en Intereses" o "IBA" por sus
                  siglas en inglés) y para mejorar y evaluar la efectividad de
                  las campañas publicitarias. Estos identificadores son
                  identificadores de dispositivos no permanentes ni personales.
                  Tal como se explica en la Cláusula 11 anterior relativa al uso
                  de "Cookies", los anunciantes y terceros también podrán
                  colocar y recopilar cookies y otros identificadores anónimos.
                  Terceros tales como Facebook y nuestros socios publicitarios
                  podrán recopilar información acerca de la actividad en línea
                  del usuario a lo largo del tiempo y de los diferentes sitios
                  web, y podrán utilizar dicha información para ofrecer anuncios
                  en otras páginas web acerca de bienes y servicios que puedan
                  ser de su interés.
                </p>
              </li>
              <li>
                <p>
                  Nuestros Sistemas no reconocen señales de "No rastrear" (Do
                  Not Track), pero muchas de las compañías de terceros descritas
                  anteriormente le permiten al usuario excluir las prácticas de
                  publicidad personalizada, o en su defecto, el usuario podrá
                  optar porque no se recopile cierta Información Personal de
                  acuerdo a lo establecido en la cláusula 12.3
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              Vigilante Renfe cumple con el Conjunto de Principios de
              Autorregulación de la Alianza de Publicidad Digital (Principios
              DAA, por sus siglas en inglés). El usuario puede optar por no
              recibir anuncios personalizados/publicidad basada en intereses por
              parte de Vigilante Renfe o proporcionados en su nombre:
            </p>
            <ul>
              <li>
                <p>
                  Para conocer más acerca de estas prácticas publicitarias o
                  para optar por no recibir este tipo de publicidad puede
                  visitar NAI: Network Advertising Initiative o WebChoices o
                  Your Online Choices. Asimismo, para sistemas operativos
                  Android, el usuario puede elegir excluir la publicidad basada
                  en intereses. Para esto, abra "Ajustes de Google" desde la
                  pestaña de aplicaciones, haga clic en "Anuncios" y seleccione
                  "Inhabilitar Personalización de anuncios" (
                  <a
                    target="_blank"
                    href="https://developer.android.com/google/play-services/id.html"
                    rel="noopener noreferrer"
                  >
                    https://developer.android.com/google/play-services/id.html
                  </a>
                  ). Tenga en cuenta que esto hará que que su teléfono no
                  permita que usted reciba recomendaciones publicitarias
                  personalizadas.
                </p>
              </li>
              <li>
                <p>
                  En sistemas operativos iOS, el usuario podrá elegir excluir la
                  publicidad basada en intereses haciendo clic en
                  "Configuración", luego "Privacidad", "Publicidad" y
                  habilitando la opción de Limitar seguimiento (
                  <a
                    target="_blank"
                    href="https://hola.apple.com/en-us/HT202074"
                    rel="noopener noreferrer"
                  >
                    https://hola.apple.com/en-us/HT202074
                  </a>
                  ). Tenga en cuenta que esto hará que que su teléfono no
                  permita que usted reciba recomendaciones publicitarias
                  personalizadas.
                </p>
              </li>
            </ul>
            <p>
              La siguiente es parte del listado de nuestros Socios Publicitarios
              y sus Políticas de Privacidad:
            </p>
            <ul>
              <li>
                <p>
                  Google:{' '}
                  <a
                    target="_blank"
                    href="https://policies.google.com/privacy"
                    rel="noopener noreferrer"
                  >
                    https://policies.google.com/privacy
                  </a>
                </p>
              </li>
              <li>
                <p>
                  Facebook:{' '}
                  <a
                    target="_blank"
                    href="https://www.facebook.com/about/privacy"
                    rel="noopener noreferrer"
                  >
                    https://www.facebook.com/about/privacy
                  </a>
                </p>
              </li>
            </ul>
            <p>
              El usuario puede obtener una lista completa y actualizada de
              nuestros Socios Publicitarios contactando a
              hola@vigilanterenfe.es.
            </p>
          </li>
          <li>
            <p>
              Los Socios Publicitarios podrán recopilar información de ubicación
              exacta y aproximada: tal como se explica en la Cláusula 2.2, puede
              que recopilemos información exacta o aproximada de ubicación.
              Asimismo, permitimos anuncios en nuestros Servicios gratuitos con
              el fin de poder continuar ofreciendo dichos Servicios de manera
              gratuita. En el momento en que el usuario activó su juego o
              Servicios, autorizó a nuestros socios publicitarios a recopilar
              información de ubicación exacta o aproximada de su dispositivo
              móvil y utilizar dicha información para brindarle publicidad
              basada en su ubicación e intereses. Es posible que el dispositivo
              móvil del usuario le permita optar porque no se recopilen datos
              exactos acerca de su ubicación, lo cual se aplicará tanto a
              nosotros como a nuestros socios publicitarios, tal como se
              describe a continuación.
            </p>
            <p>
              iOS – Para desactivar la recopilación de Datos Exactos de
              Ubicación en iOS, podrá desactivar los Servicios de Localización
              de las aplicaciones pertinentes de Vigilante Renfe a través del
              menú "Configuración" > Privacidad > Localización". Luego,
              seleccione las aplicaciones pertinentes de Vigilante Renfe y
              cambie el estado de "Permitir acceso a ubicación" a "Nunca". Por
              información adicional de Apple, ingrese aquí{' '}
              <a
                target="_blank"
                href="https://hola.apple.com/en-us/HT203033"
                rel="noopener noreferrer"
              >
                https://hola.apple.com/en-us/HT203033
              </a>
              .
            </p>
            <p>
              Android (6.0/Marshmallow y versiones posteriores) – Para
              desactivar la recopilación de Datos Exactos de Ubicación en
              Android, podrá desactivar los Servicios de Localización de las
              aplicaciones pertinentes de Vigilante Renfe a través del menú
              "Configuración > Apps > Vigilante Renfe > Permisos > Ubicación".
              Luego, desactive el botón de "Ubicación".
            </p>
          </li>
        </ol>
        <h3>
          <li>PRIVACIDAD PARA MENORES DE EDAD</li>
        </h3>
        <p>
          Nuestros Servicios están orientados a una audiencia general, y no
          orientados a menores de edad.
        </p>
        <ol>
          <li>
            <p>
              COPPA. Vigilante Renfe no tiene la intención de recopilar
              información personal de niños menores de 13 años ("Información
              Personal de Menores") de una manera que no esté permitida por la
              Ley de Protección de la Privacidad de Menores de los Estados
              Unidos ("COPPA", por sus siglas en inglés). Para algunos
              Servicios, proporcionamos una versión del Servicio que no
              recolecta la Información Personal de Menores, excepto conforme a
              los permisos de COPPA. Si el usuario cree que hemos recolectado o
              estamos usando la Información Personal de un menor, o que su hijo
              menor de 13 años de edad ha identificado erróneamente su edad, por
              favor contáctenos en la dirección hola@vigilanterenfe.es y
              nosotros desactivaremos esa cuenta, y eliminaremos la Información
              Personal del menor asociada a ésta en la medida requerida por
              COPPA.
            </p>
          </li>
          <li>
            <p>
              Normativa europea de protección de datos de carácter personal.
              Igualmente, Vigilante Renfe no tiene la intención de recopilar
              datos de carácter personal de menores de 16 años que accedan y/o
              utilicen los Servicios. De conformidad con el Reglamento (UE)
              2016/679 del Parlamento Europeo y del Consejo de 27 de abril de
              2016 relativo a la protección de las personas físicas en lo que
              respecta al tratamiento de datos personales y a la libre
              circulación de estos (Reglamento general de protección de datos),
              si el usuario es menor de 16 años debe contar con el
              consentimiento de su tutor legal y solo en la medida en que el
              tutor legal está de acuerdo con esta Política de Privacidad para
              acceder y/o utilizar los Servicios. En caso de que la legislación
              nacional aplicable del usuario lo permita, el usuario menor de 16
              años puede acceder al servicio, siempre que esta no sea inferior a
              13 años.
            </p>
            <p>
              Para algunos Servicios, proporcionamos una versión del Servicio
              non-collecting, es decir, que no recolecta Información Personal,
              para menores. Si el usuario menor cree que hemos recolectado o
              estamos usando su Información Personal, o que su hijo menor de 16
              años de edad, o en su caso de 13 años de edad, ha identificado
              erróneamente su edad, por favor contáctenos en la dirección
              hola@vigilanterenfe.es y nosotros desactivaremos esa cuenta, y
              eliminaremos la Información Personal del menor asociada a aquella.
            </p>
          </li>
        </ol>
        <h3>
          <li>LEGISLACIÓN APLICABLE Y JURISDICCIÓN</li>
        </h3>
        <ol>
          <li>
            <p>
              Residentes en los Estados Unidos. Para los residentes en los
              Estados Unidos, estas Políticas de Privacidad se regirán e
              interpretarán de conformidad con la legislación federal de los
              Estados Unidos y la legislación del Estado de Nueva York, sin
              causar ningún conflicto de norma, leyes o disposiciones. ESTAS
              POLÍTICAS SON UNA PARTE DE NUESTRAS CONDICIONES DEL SERVICIO Y
              COMO TAL, CUALQUIER DISPUTA QUE SURJA DE O SE RELACIONE CON ESTAS
              POLÍTICAS O SU USO DEL SITIO O DE LOS SERVICIOS ESTÁ SUJETA A
              ARBITRAJE VINCULANTE Y UNA RENUNCIA DE LOS DERECHOS DE ACCIÓN
              COLECTIVA TAL COMO SE DETALLA EN LA CLÁUSULA 15 DE NUESTROS
              TÉRMINOS DEL SERVICIO.
            </p>
            <p>
              Si cualquier tribunal arbitral u otra autoridad competente
              considera que cualquier disposición de estas Políticas de
              Privacidad resultara ser inválida o no se pudiese exigir su
              cumplimento, los restantes términos de estas Políticas de
              Privacidad no se verán afectados.
            </p>
          </li>
          <li>
            <p>
              Usuarios que se encuentren en Europa - ARBITRAJE VINCULANTE: Las
              presentes disposiciones se aplican al Usuario cuando éste acceda,
              descargue y use nuestros Servicios en cualquier Estado Miembro de
              la Unión Europea, y en la medida en que su jurisdicción lo
              permita. ESTAS POLÍTICAS SON UNA PARTE DE NUESTRAS CONDICIONES DEL
              SERVICIO Y COMO TAL, CUALQUIER DISPUTA QUE SURJA DE O SE RELACIONE
              CON ESTAS POLÍTICAS O SU USO DEL SITIO O DE LOS SERVICIOS ESTÁ
              SUJETA A ARBITRAJE VINCULANTE TAL COMO SE DETALLA EN LA CLÁUSULA
              16 DE NUESTROS TÉRMINOS DEL SERVICIO.
            </p>
            <p>
              Si cualquier tribunal arbitral u otra autoridad competente
              considera que cualquier disposición de estas Políticas de
              Privacidad resultara ser inválida o no se pudiese exigir su
              cumplimento, los restantes términos de estas Políticas de
              Privacidad no se verán afectados.
            </p>
          </li>
          <li>
            <p>
              Residentes en otros países. Esta Política de Privacidad se regirá
              e interpretará de conformidad con la legislación de España. Todas
              las reclamaciones, procedimientos legales o litigios que surjan de
              o en relación a estas Políticas, el Sitio o los Servicios, serán
              presentadas únicamente ante los tribunales pertinentes ubicados en
              la ciudad de Vigo, España. El usuario consiente a la jurisdicción
              y sede de tales tribunales y renuncia a cualquier objeción en
              cuanto a la inconveniencia del foro.
            </p>
            <p>
              Si cualquier organismo del Poder Judicial u otra autoridad
              competente considera que cualquier disposición de estas Políticas
              de Privacidad resultara ser inválida o no se pudiese exigir su
              cumplimento, los restantes términos de estas Políticas de
              Privacidad no se verán afectados.
            </p>
          </li>
        </ol>
        <h3>
          <li>CAMBIOS A LAS POLÍTICAS DE PRIVACIDAD</li>
        </h3>
        <p>
          La versión más actual de la política regirá el uso de la información y
          estará disponible en{' '}
          <a
            target="_blank"
            href="https://vigilanterenfe.es/privacidad"
            rel="noopener noreferrer"
          >
            https://vigilanterenfe.es/privacidad
          </a>
        </p>
        <p>
          Vigilante Renfe, ocasionalmente y a su discreción, puede revisar o
          modificar la presente Política de Privacidad de vez en cuando. Si
          decidimos cambiar esta Política de Privacidad, informaremos al usuario
          mediante la publicación de la nueva Política de Privacidad revisada en
          el Sitio. Vigilante Renfe también puede, pero no está obligado a,
          notificarle los cambios de la Política de Privacidad por correo
          electrónico a la dirección de correo asociada a su cuenta o mostrando
          un aviso destacado en los Servicios.
        </p>
        <p>
          En caso de oponerse a cualquier cambio en la Política de Privacidad,
          el usuario debe inmediatamente cesar en el uso de los Servicios y
          cerrar las cuentas relacionadas. Al continuar accediendo a, o usar los
          Servicios después de que los cambios sean efectivos, el usuario acepta
          estar sujeto a la nueva Política de Privacidad actualizada.
        </p>
        <p>©2020 Vigilante Renfe. Todos los derechos reservados.</p>
      </ol>
    </section>
  </Layout>
);

export default PrivacyPage;
